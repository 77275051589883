import Multiselect from 'multiselect-react-dropdown';
import { useState } from 'react';
import { RejectionPicklist  } from './DropdownOptions';
export default function CommanDropdown(props) {
  
  const [selected, setselected] = useState();

  const options = [
    {
      key: '',
      value: 'Select',
    },
  ];
  
  function handleSelectedChanged(selected) {
    console.log(selected);
    // props.onChangestartDate(date);
    // setStartDate(date)
}
  return (
    <>
      <Multiselect
        displayValue="value"
        onKeyPressFn={function noRefCheck() {}}
        onRemove={function noRefCheck() {}}
        onSearch={function noRefCheck() {}}
        onSelect={handleSelectedChanged}
        selected={selected}
        options={RejectionPicklist.map((_) => ({
          label: _.key,
          value: _.value,
        }))}
        placeholder= {props.placeholder || "Rejection Type" }
        style={{
          chips: {
            background: '#3276b1',
          },

          searchBox: {
            width: '550px',
            height: '40px',
          },
        }}
        showCheckbox
      />
    </>
  );
}
