import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

//export default class Menu extends Component {
const Menu = (props) => {
  const navigate = useNavigate();

  const onButtonClick = () => {
    localStorage.removeItem('providerCode');
    navigate('/');
  };

  return (
    <div>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <Link to="/home" className="nav-link">
          <h4 style={{ color: 'aliceblue', }}>Provider Portal</h4>
        </Link>
        {/* Sidebar */}
        <div className="sidebar">
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li className="nav-item">
                <Link to="/home" className="nav-link">
                  Home
                </Link>
              </li>
             
              <li className="nav-item">
                <Link to="/docViewerDWV" className="nav-link">
                  Document Viewer - DWV
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/" onClick={onButtonClick} className="nav-link">
                  Logout
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
    </div>
  );
};

export default Menu;
