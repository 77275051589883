import React, { useState, useEffect } from 'react';
import Layout from '../component/Layout.js';
import '../css/docViewer.css';

const DocViewer = (props) => {
  useEffect(() => {
    document.title = 'Provider portal - Viewer';
  });

 


  return (
    <Layout>
      
    </Layout>
  );
};

export default DocViewer;
