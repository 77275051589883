import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './component/Login';
import './css/App.css';
import { useEffect, useState } from 'react';
import Home from './component/Home.js';
import DocViewer from './component/DocViewer.js';
import DwvComponent from './component/DwvComponent.js';

import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { indigo, pink } from '@mui/material/colors';

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [proCode, setproCode] = useState('');

  useEffect(() => {
    const providerCode = JSON.parse(localStorage.getItem('providerCode'));
    if (!providerCode || !providerCode.passkey) {
      setLoggedIn(false);
      return;
    } else {
      setLoggedIn(true);
      setproCode(providerCode.passkey || '');
    }
  }, []);

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = createTheme({
    typography: {
      useNextVariants: true,
    },
    palette: {
      primary: {
        main: indigo[500],
      },
      secondary: {
        main: pink[500],
      },
      mode: prefersDarkMode ? 'dark' : 'light',
    },
  });

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="/home"
            element={
              <Home
                proCode={proCode}
                loggedIn={loggedIn}
                setLoggedIn={setLoggedIn}
              />
            }
          />
          <Route
            path="/docViewer"
            element={
              <DocViewer
                proCode={proCode}
                loggedIn={loggedIn}
                setLoggedIn={setLoggedIn}
              />
            }
          />
          <Route
            path="/docViewerDWV"
            element={
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <DwvComponent
                  proCode={proCode}
                  loggedIn={loggedIn}
                  setLoggedIn={setLoggedIn}
                />
              </ThemeProvider>
            }
          />

          <Route
            path="/"
            element={
              <Login
                setLoggedIn={setLoggedIn}
                loggedIn={loggedIn}
                setproCode={setproCode}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
