import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/adminlte.min.css';
import logoDark from '../images/nrr_logo.png';

const Login = (props) => {
  function handleKeyDown(event) {
    if (event.key === 'Enter') {
      onButtonClick();
    }
  }

  const navigate = useNavigate();

  if (localStorage.getItem('providerCode')) {
    navigate('/home');
  }

  //console.log(props.loggedIn);
  const [passkey, setCode] = useState('');
  const [proCodeError, setCodeError] = useState('');

  const onButtonClick = () => {
    // Set initial error values to empty
    setCodeError('');
    // Check if the user has entered both fields correctly
    if ('' === passkey) {
      setCodeError('Please enter Code');
      return;
    }

    if (passkey.length < 7) {
      setCodeError('The code must be 8 characters or longer');
      return;
    }

    // Check if email has an account associated with it
    logIn();
  };

  // Log in a user using email and password
  const logIn = () => {
    fetch('https://api2.nationalrr.com/api/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ passkey }),
    })
      .then((r) => r.json())
      .then((r) => {
        if (r.success) {
          localStorage.setItem('providerCode', JSON.stringify({ passkey }));
          props.setLoggedIn(true);
          props.setproCode(passkey);
          navigate('/home');
        } else {
          setCodeError(r.data);
        }
      });
  };

  return (
    <>
      <div className="hold-transition login-page">
        <div className="login-box">
          <div className="login-logo">
            <span className="logo-lg">
              <img src={logoDark} alt="" height="61" />
            </span>
          </div>
          <div className="card">
            <div className="card-body login-card-body">
              <h3>Welcome Back !</h3>
              <p className="login-box-msg">Log in to continue to Provider Portal</p>
              <div className="input-group mb-3">
                <input
                  value={passkey}
                  placeholder="Enter your code here"
                  onChange={(ev) => setCode(ev.target.value)}
                  onKeyDown={handleKeyDown}
                  className='inputBox'
                />
              </div>
              <label className="col-10 errorLabel">{proCodeError}</label>
              <div className="row">
                <div className="col-12">
                  <button
                    type="submit"
                    onClick={onButtonClick}
                    className="btn btn-primary btn-block"
                  >
                    Log in
                  </button>
                </div>
              </div>
              <div className="text-center my-5">
                <div
                  className="alert alert-borderless notes text-center mb-2 mx-2"
                  role="alert"
                >
                  Protected Health Information in this system is subject to
                  Public Law 104-191, the Health Insurance Portability and
                  Accountability Act of 1996 and the Final Privacy Rule and
                  Final Security Rule codified in 45 C.F.R. § 160 and 164.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
