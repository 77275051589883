import React from 'react';
import CommanDropdown from './CommanDropdown.js';

const ReportDeficiency = (props :any) => {
    return (
        <section className="content">
            <div className="container-fluid">
                    <div className="card card-info"><div className="card-header">
                            <p className="card-title">Report Deficiency/Issue </p>
                    </div>
                        <form className="form-horizontal">
                            <div className="card-body">
                            <div className="form-group row">
                                <div className="col-sm-9">
                                    <CommanDropdown />
                                </div>
                            </div>
                            <div className="form-group row">
                                    <div className="col-sm-12">
                                    <textarea id="more_details" className="form-control" placeholder="Add more detaile" name="more_details" rows={4} cols={50}/>
                                    </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <button type="submit" className="btn btn-primary ">Submit</button>
                        </div>
                        </form>
                    </div>
                </div>
            </section>

    );
}
export default ReportDeficiency;    