import React from 'react';
import Header from '../component/Header';
import Menu from '../component/Menu';
import Footer from '../component/Footer';

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <Header />
      <Menu />
      <div className="wrapper">
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              {/* <div className="row mb-">
                <div className="col-sm-2">
                  <h1 className="m-8">{document.title1}</h1>
                </div>
              </div> */}
            </div>
          </div>
          <section className="content">
            {/* <div className="container-fluid">
              <div className="row">
                <section className="col-lg-12">
                  <div className="container-fluid">
                    <div className="row">
                        <div>{children}</div>
                    </div>
                  </div>
                </section>
              </div>
            </div> */}

<div>{children}</div>
          </section>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};
export default Layout;
