import React from 'react';
import ChoiseDropdown from './ChoiseDropdown';
import Layout from './Layout';

const ConfirmReceipt = (props :any) => {
    return (
        <section className="content">
            <div className="container-fluid">
                    <div className="card card-info">
                    <div className="card-header">
                            <p className="card-title"></p>
                    </div>
                        <form className="form-horizontal">
                            <div className="card-body">
                                <div className="form-group row">
                                    <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">Select Status</label>
                                <div className="col-sm-9">
                                    <ChoiseDropdown />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="name" className="col-sm-3 col-form-label">Name</label>
                                    <div className="col-sm-9">
                                        <input type="text" className="form-control" id="person_name" placeholder="Reporting Person Name" />
                                    </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <button type="submit" className="btn btn-primary ">Submit</button>
                        </div>
                        </form>
                    </div>
                </div>
            </section>
    );
}
export default ConfirmReceipt;    