import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';

async function getBase64(file, callback) {
  const reader = new FileReader();
  reader.onload = () => callback(null, reader.result);
  reader.onerror = (error) => callback(error);
  reader.readAsDataURL(file);
}

const FileUploader = () => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [base64File, setbase64File] = useState(null);
  const [fileUploadError, setfileUploadError] = useState('');

  const base64Callback = async (err, res) => {
    if (!err) {
      setbase64File(res); // setting the state is the important part
    } else {
      setbase64File(null);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setUploadedFiles(acceptedFiles);

      const formData = new FormData();
      const myFile = acceptedFiles;

      for (let i = 0; i < myFile.length; i++) {
        getBase64(myFile[i], base64Callback);
      }
     
      formData.append('mypic', myFile);
      let providerCode = localStorage.getItem('providerCode')
      formData.append("passkey",providerCode);
     
      try {
        fetch('https://api2.nationalrr.com/api/multiple-upload', {
          method: 'POST',
          body: formData,
        })
        .then((response) => response.json())
          .then(function (response) {
            return response;
          })
          .then(function (response) {
            if (response) {
              alert(response.message);
              setUploadedFiles([]);
            } else {
              alert(response.data);
            }
            
          });
      } catch (e) {
        console.log(e.message);
      }
      // Call your backend API endpoint to upload files
    },
  });
  return (
          <section className="content">
            <div className="container-fluid">
              <div className="card card-info">
                <div className="card-header">
                  <h3 ></h3>
                </div>
                
                <div className="card-body">
                  <div className="form-group row">
                    <div className="col-md-12">
                      <div className="file-upload-box">
                        <div className="dropzone" onDrop={(e) => handleDrop(e)}>
                          <div {...getRootProps()}>
                            <input {...getInputProps()} accept="image/*" />
                              <label>Drag and drop files here or click to browse.</label>
                          </div>
                        </div>
                      </div>
                      <label className="col-sm-3 col-form-label">{fileUploadError}</label>
                        <ul>
                              {uploadedFiles.map((file) => (
                            <li key={file.name}>{file.name}</li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
    
  );
};
export default FileUploader;
