import React from 'react';
import ChoiseDropdown from './ChoiseDropdown';
import Layout from './Layout';

const NoRecord = (props :any) => {
    return (
        <>
        <section className="content">
            <div className="container-fluid">
                    <div className="card card-info">
                    <div className="card-header">
                            <p className="card-title">Statement No Records</p>
                    </div>
                        <form className="form-horizontal">
                            <div className="card-body">
                                <div className="form-group row">
                                    <div className="col-sm-3">
                                        <input  type="checkbox" id="medical" />&nbsp;
                                        <label> Medical</label>
                                    </div>
                                    <div className="col-sm-3">
                                        <input  type="checkbox" id="xray" />&nbsp;
                                        <label>X-rays</label>
                                    </div>
                                    <div className="col-sm-3">
                                        <input  type="checkbox" id="lab" />&nbsp;
                                        <label>Lab</label>
                                    </div>
                                    <div className="col-sm-3">
                                        <input  type="checkbox" id="billing" />&nbsp;
                                        <label>Billing</label>
                                    </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="name" className="col-sm-4 col-form-label float-right">Person Reporting</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" id="person_name" placeholder="Reporting Person Name" />
                                    </div>
                                </div>
                             <div className="form-group row">
                                <label htmlFor="name" className="col-sm-4 col-form-label float-right">Reason</label>
                                    <div className="col-sm-8">
                                        <textarea id="reason" className="form-control" placeholder="Reason" name="w3review" rows={4} cols={50}/>
                                    </div>
                            </div>
                        </div>
                        <div className="card-footer">
                                <button type="button" className="btn btn-default float-right">Cancel</button>
                                <button type="submit" className="btn btn-primary  float-right">Submit</button>
                        </div>
                        </form>
                    </div>
                </div>
            </section>

        </>
    );
}
export default NoRecord;    