 export const RejectionPicklist = [
    {
      key: 1,
      value: "Authorization missing"
    },
  
    {
      key: 2,
      value: "Provider specific auth required"
    },
    {
      key: 3,
      value: "DC and supporting docs missing"
    },
    {
      key: 4,
      value: "DOB discrepancy"
    },
    {
      key: 5,
      value: "Incorrect/missing provider address"
    },
    {
      key: 6,
      value: "Provider closed"
    },
    {
      key: 7,
      value: "Authorization not signed by patient"
    },
    {
      key: 8,
      value: "Illegible authorization/documentation"
    },
    {
      key: 9,
      value: "Sensitive info release missing"
    },
    {
      key: 10,
      value: "All DOS not accepted"
    },
    {
      key: 11,
      value: "Other rejection"
    },
    {
      key: 12,
      value: "Signature discrepancy"
    },
    {
      key: 13,
      value: "Client action required"
    },
    {
      key: 14,
      value: "No response from claimant"
    } 
];
