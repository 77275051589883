import ddOption from '../component/ddOption.json';
import Select from 'react-select';

export default function ChoiseDropdown() {
  return (
    <div>
      <Select
        placeholder="Select Any one"
        options={ddOption.map((_) => ({
          label: _.title,
          value: _.id,
        }))}
      />
    </div>
  );
}
