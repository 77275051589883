import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import FileUploader from '../component/FileUploader';
import ConfirmReceipt from '../component/ConfirmReceipt.tsx';
import ReportDeficiency from '../component/ReportDeficiency';
import NoRecord from '../component/NoRecord';
import 'react-dropdown/style.css';
import ChoiseDropdown from '../component/ChoiseDropdown.js';
import Select from 'react-select';
import Layout from '../component/Layout.js';

const Home = (props) => {
  const navigate = useNavigate();

  if (!localStorage.getItem('providerCode')) {
    navigate('/');
  }

  const { loggedIn, setloggedIn } = props;
  const [FirstDropdown, setFirstDropdown] = useState(false);
  const [SecondDropdown, setSecondDropdown] = useState(false);
  const [ThirdDropdown, setThirdDropdown] = useState(false);
  const [FourDropdown, setFourDropdown] = useState(false);
  const [selectedOption, setSelectedOption] = useState('none');

  useEffect(() => {
    document.title = 'Provider portal - Home';
  });

  const options = [
    { value: '1', label: 'Upload a Record Upload a Record or Document' },
    { value: '2', label: 'Confirm Receipt of a Request and Report Expected Turnaround Time' },
    { value: '3', label: 'Confirm Receipt of Request and Report Deficiency/Issue causing Request to not be fulfilled until rectified' },
    { value: '4', label: 'No Records on File – record a statement of no record' },
  ];

  const handleTypeSelect = (e) => {
    setSelectedOption(e.value);

    if (e.value === '1') {
      setFirstDropdown(true);
    } else {
      setFirstDropdown(false);
    }
    if (e.value === '2') {
      setSecondDropdown(true);
    } else {
      setSecondDropdown(false);
    }
    if (e.value === '3') {
      setThirdDropdown(true);
    } else {
      setThirdDropdown(false);
    }
    if (e.value === '4') {
      setFourDropdown(true);
    } else {
      setFourDropdown(false);
    }
    
  };

  return (
    <Layout>
      <div className="container-fluid">
        <div className="row">

          
          <section className="col-lg-12">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-8 offset-md-2">
                    {loggedIn ? (
                      <div>
                        <Select
                          className={'selectminwidth'}
                          options={options}
                          onChange={handleTypeSelect}
                          value={options.filter(function (option) {
                            return option.value === selectedOption;
                          })}
                          placeholder="What do you want to do?"
                        />
                         <div>
                          <br/>
                         {FirstDropdown ? <FileUploader /> : null}
                          {SecondDropdown ? <ConfirmReceipt /> : null}
                          {ThirdDropdown ? <ReportDeficiency /> : null}
                          {FourDropdown ? <NoRecord /> : null}
                        </div>
                      </div>
                    ) : (
                      <div />
                    )}
                     
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default Home;
